import logo from './logo.svg';
import './App.css';
import  Sidebar from './Component/Home/Sidebar';
import Header from './Component/Home/Header';

// import data from './table.json'

function App() {
  return (
    <div>
      <Header/>
      {/* <Sidebar/> */}
      
    </div>
  );
}

export default App;
