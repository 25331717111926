import React from 'react';
import axios from 'axios';
import { format } from "date-fns";

// import './App.css';

//Bootstrap and jQuery libraries
// import 'https://cdn.datatables.net/1.10.24/css/jquery.dataTables.min.css';
// import 'https://code.jquery.com/jquery-3.6.0.min.js';
// import 'https://code.jquery.com/jquery-3.5.1.js';
// import 'https://cdn.datatables.net/1.10.24/js/jquery.dataTables.min.js';

//Datatable Modules
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

class App extends React.Component {

    state = {
        persons: []
    }
    componentDidMount() {
        axios.get('https://demo.jithvar.com/avani/api/gettable')
        .then(res => {
            const persons = res.data;
            this.setState({ persons });
            console.log(this.state.persons);
        }).catch(error => {
            console.log(error);
        })

        //initialize datatable
        // $(document).ready(function () {
        //     $('#example').DataTable();
        // });
    }

    
  render(){
    //Datatable HTML
  return (
    <div className="">
      <div className="container-fluid">
          <div className="card" style={{height: '500px'}}>
            <div className="card-body">
                <table id="example" className="table table-bordered">
                    <thead>
                        <tr>
                            {/* <th>Id</th> */}
                            <th>Intake Date</th>
                            <th>HEI Ref. ID</th>
                            <th>Name</th>
                            <th>Age</th>
                            <th>Gender</th>
                            <th>Country</th>
                            <th>Ethnicity</th>
                            <th>AA Status</th>
                            <th>BAI/BDI</th>
                            <th>IESR</th>
                            <th>File Status</th>
                            <th>Changed On</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.persons.map(person => 
                            <tr key={ person._id }>
                                {/* <td>#</td> */}
                                <td>{person.intakedate}</td>
                                <td>{person.refid}</td>
                                <td>{person.name}</td>
                                <td>{person.age}</td>
                                <td>{person.gender}</td>
                                <td>{person.country}</td>
                                <td>{person.ethnicity}</td>
                                <td>{person.aastatus}</td>
                                <td>{person.baibdi}</td>
                                <td>{person.iesr}</td>
                                <td>{person.filestatus}</td>
                                <td>{person.chagedon}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
      </div>
  );
}
}
export default App;