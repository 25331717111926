import React from 'react';
import Table from '../Home/Table';
// import Tabled from '../Home/Tabled';


class Header extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="c-app">
                    <div className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show" id="sidebar">
                        <div className="c-sidebar-brand d-lg-down-none" style={{fontSize:30}}>
                            <strong>CoreUI</strong>
                        </div>
                        <ul className="c-sidebar-nav">
                            <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="index.html">
                                <svg className="c-sidebar-nav-icon">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-speedometer"></use>
                                </svg> Dashboard<span className="badge badge-info">NEW</span></a></li>
                            {/* <li className="c-sidebar-nav-title">Theme</li> */}
                            {/* <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="colors.html">
                                <svg className="c-sidebar-nav-icon">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-drop"></use>
                                </svg> Colors</a></li>
                            <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="typography.html">
                                <svg className="c-sidebar-nav-icon">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-pencil"></use>
                                </svg> Typography</a></li>
                            <li className="c-sidebar-nav-title">Components</li>
                            <li className="c-sidebar-nav-item c-sidebar-nav-dropdown"><a className="c-sidebar-nav-link c-sidebar-nav-dropdown-toggle" href="#">
                                <svg className="c-sidebar-nav-icon">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-puzzle"></use>
                                </svg> Base</a>
                                <ul className="c-sidebar-nav-dropdown-items">
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/breadcrumb.html"><span className="c-sidebar-nav-icon"></span> Breadcrumb</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/cards.html"><span className="c-sidebar-nav-icon"></span> Cards</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/carousel.html"><span className="c-sidebar-nav-icon"></span> Carousel</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/collapse.html"><span className="c-sidebar-nav-icon"></span> Collapse</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/forms.html"><span className="c-sidebar-nav-icon"></span> Forms</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/jumbotron.html"><span className="c-sidebar-nav-icon"></span> Jumbotron</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/list-group.html"><span className="c-sidebar-nav-icon"></span> List group</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/navs.html"><span className="c-sidebar-nav-icon"></span> Navs</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/pagination.html"><span className="c-sidebar-nav-icon"></span> Pagination</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/popovers.html"><span className="c-sidebar-nav-icon"></span> Popovers</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/progress.html"><span className="c-sidebar-nav-icon"></span> Progress</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/scrollspy.html"><span className="c-sidebar-nav-icon"></span> Scrollspy</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/switches.html"><span className="c-sidebar-nav-icon"></span> Switches</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/tables.html"><span className="c-sidebar-nav-icon"></span> Tables</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/tabs.html"><span className="c-sidebar-nav-icon"></span> Tabs</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="base/tooltips.html"><span className="c-sidebar-nav-icon"></span> Tooltips</a></li>
                                </ul>
                            </li> */}
                            {/* <li className="c-sidebar-nav-item c-sidebar-nav-dropdown"><a className="c-sidebar-nav-link c-sidebar-nav-dropdown-toggle" href="#"> */}
                                {/* <svg className="c-sidebar-nav-icon">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-cursor"></use>
                                </svg> Buttons</a>
                                <ul className="c-sidebar-nav-dropdown-items">
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="buttons/buttons.html"><span className="c-sidebar-nav-icon"></span> Buttons</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="buttons/button-group.html"><span className="c-sidebar-nav-icon"></span> Buttons Group</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="buttons/dropdowns.html"><span className="c-sidebar-nav-icon"></span> Dropdowns</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="buttons/brand-buttons.html"><span className="c-sidebar-nav-icon"></span> Brand Buttons</a></li>
                                </ul>
                            </li>
                            <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="charts.html">
                                <svg className="c-sidebar-nav-icon">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-chart-pie"></use>
                                </svg> Charts</a></li>
                            <li className="c-sidebar-nav-dropdown"><a className="c-sidebar-nav-dropdown-toggle" href="#">
                                <svg className="c-sidebar-nav-icon">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-star"></use>
                                </svg> Icons</a>
                                <ul className="c-sidebar-nav-dropdown-items">
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="icons/coreui-icons-free.html"> CoreUI Icons<span className="badge badge-success">Free</span></a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="icons/coreui-icons-brand.html"> CoreUI Icons - Brand</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="icons/coreui-icons-flag.html"> CoreUI Icons - Flag</a></li>
                                </ul>
                            </li>
                            <li className="c-sidebar-nav-item c-sidebar-nav-dropdown"><a className="c-sidebar-nav-link c-sidebar-nav-dropdown-toggle" href="#">
                                <svg className="c-sidebar-nav-icon">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-bell"></use>
                                </svg> Notifications</a>
                                <ul className="c-sidebar-nav-dropdown-items">
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="notifications/alerts.html"><span className="c-sidebar-nav-icon"></span> Alerts</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="notifications/badge.html"><span className="c-sidebar-nav-icon"></span> Badge</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="notifications/modals.html"><span className="c-sidebar-nav-icon"></span> Modals</a></li>
                                </ul>
                            </li> */}
                            {/* <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="widgets.html">
                                <svg className="c-sidebar-nav-icon">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-calculator"></use>
                                </svg> Widgets<span className="badge badge-info">NEW</span></a></li>
                            <li className="c-sidebar-nav-divider"></li>
                            <li className="c-sidebar-nav-title">Extras</li>
                            <li className="c-sidebar-nav-item c-sidebar-nav-dropdown"><a className="c-sidebar-nav-link c-sidebar-nav-dropdown-toggle" href="#">
                                <svg className="c-sidebar-nav-icon">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-star"></use>
                                </svg> Pages</a>
                                <ul className="c-sidebar-nav-dropdown-items">
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="login.html" target="_top">
                                        <svg className="c-sidebar-nav-icon">
                                            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-account-logout"></use>
                                        </svg> Login</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="register.html" target="_top">
                                        <svg className="c-sidebar-nav-icon">
                                            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-account-logout"></use>
                                        </svg> Register</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="404.html" target="_top">
                                        <svg className="c-sidebar-nav-icon">
                                            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-bug"></use>
                                        </svg> Error 404</a></li>
                                    <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link" href="500.html" target="_top">
                                        <svg className="c-sidebar-nav-icon">
                                            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-bug"></use>
                                        </svg> Error 500</a></li>
                                </ul>
                            </li> */}
                            {/* <li className="c-sidebar-nav-item mt-auto"><a className="c-sidebar-nav-link c-sidebar-nav-link-success" href="https://coreui.io" target="_top">
                                <svg className="c-sidebar-nav-icon">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-cloud-download"></use>
                                </svg> Download CoreUI</a></li>
                            <li className="c-sidebar-nav-item"><a className="c-sidebar-nav-link c-sidebar-nav-link-danger" href="https://coreui.io/pro/" target="_top">
                                <svg className="c-sidebar-nav-icon">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-layers"></use>
                                </svg> Try CoreUI<strong>PRO</strong></a></li> */}
                        </ul>
                        <button className="c-sidebar-minimizer c-class-toggler" type="button" data-target="_parent" data-className="c-sidebar-minimized"></button>
                    </div>
                    <div className="c-wrapper c-fixed-components">
                        <header className="c-header c-header-light c-header-fixed c-header-with-subheader">
                            <button className="c-header-toggler c-class-toggler d-lg-none mfe-auto" type="button" data-target="#sidebar" data-className="c-sidebar-show">
                                <svg className="c-icon c-icon-lg">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-menu"></use>
                                </svg>
                            </button><a className="c-header-brand d-lg-none" href="#">
                                <svg width="118" height="46" alt="CoreUI Logo">
                                    <use xlinkHref="assets/brand/coreui.svg#full"></use>
                                </svg></a>
                            <button className="c-header-toggler c-class-toggler mfs-3 d-md-down-none" type="button" data-target="#sidebar" data-className="c-sidebar-lg-show" responsive="true">
                                <svg className="c-icon c-icon-lg">
                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-menu"></use>
                                </svg>
                            </button>
                            <ul className="c-header-nav d-md-down-none">
                                <li className="c-header-nav-item px-3"><a className="c-header-nav-link" href="#">Dashboard</a></li>
                                <li className="c-header-nav-item px-3"><a className="c-header-nav-link" href="#">Users</a></li>
                                <li className="c-header-nav-item px-3"><a className="c-header-nav-link" href="#">Settings</a></li>
                            </ul>
                            <ul className="c-header-nav ml-auto mr-4">
                                <li className="c-header-nav-item d-md-down-none mx-2"><a className="c-header-nav-link" href="#">
                                    <svg className="c-icon">
                                        <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-bell"></use>
                                    </svg></a></li>
                                <li className="c-header-nav-item d-md-down-none mx-2"><a className="c-header-nav-link" href="#">
                                    <svg className="c-icon">
                                        <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-list-rich"></use>
                                    </svg></a></li>
                                <li className="c-header-nav-item d-md-down-none mx-2"><a className="c-header-nav-link" href="#">
                                    <svg className="c-icon">
                                        <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-envelope-open"></use>
                                    </svg></a></li>
                                <li className="c-header-nav-item dropdown"><a className="c-header-nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                    <div className="c-avatar"><img className="c-avatar-img" src="../assets/img/6.jpg" alt="user@email.com"/></div>
                                </a>
                                    <div className="dropdown-menu dropdown-menu-right pt-0">
                                        <div className="dropdown-header bg-light py-2"><strong>Account</strong></div><a className="dropdown-item" href="#">
                                            <svg className="c-icon mr-2">
                                                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-bell"></use>
                                            </svg> Updates<span className="badge badge-info ml-auto">42</span></a><a className="dropdown-item" href="#">
                                            <svg className="c-icon mr-2">
                                                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-envelope-open"></use>
                                            </svg> Messages<span className="badge badge-success ml-auto">42</span></a><a className="dropdown-item" href="#">
                                            <svg className="c-icon mr-2">
                                                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-task"></use>
                                            </svg> Tasks<span className="badge badge-danger ml-auto">42</span></a><a className="dropdown-item" href="#">
                                            <svg className="c-icon mr-2">
                                                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-comment-square"></use>
                                            </svg> Comments<span className="badge badge-warning ml-auto">42</span></a>
                                        <div className="dropdown-header bg-light py-2"><strong>Settings</strong></div><a className="dropdown-item" href="#">
                                            <svg className="c-icon mr-2">
                                                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-user"></use>
                                            </svg> Profile</a><a className="dropdown-item" href="#">
                                            <svg className="c-icon mr-2">
                                                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-settings"></use>
                                            </svg> Settings</a><a className="dropdown-item" href="#">
                                            <svg className="c-icon mr-2">
                                                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-credit-card"></use>
                                            </svg> Payments<span className="badge badge-secondary ml-auto">42</span></a><a className="dropdown-item" href="#">
                                            <svg className="c-icon mr-2">
                                                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-file"></use>
                                            </svg> Projects<span className="badge badge-primary ml-auto">42</span></a>
                                        <div className="dropdown-divider"></div><a className="dropdown-item" href="#">
                                            <svg className="c-icon mr-2">
                                                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-lock-locked"></use>
                                            </svg> Lock Account</a><a className="dropdown-item" href="#">
                                            <svg className="c-icon mr-2">
                                                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-account-logout"></use>
                                            </svg> Logout</a>
                                    </div>
                                </li>
                            </ul>
                            <div className="c-subheader px-3">

                                <ol className="breadcrumb border-0 m-0">
                                    <li className="breadcrumb-item">Home</li>
                                    <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                    <li className="breadcrumb-item active">Dashboard</li>

                                </ol>
                            </div>
                        </header>
                        <div className="c-body">
                            <Table/>
                            {/* <Tabled/> */}
                            
                            {/* <main className="c-main">
                                <div className="container-fluid">
                                    <div className="fade-in">
                                        <div className="row">
                                            <div className="col-sm-6 col-lg-3">
                                                <div className="card text-white bg-primary">
                                                    <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                                                        <div>
                                                            <div className="text-value-lg">9.823</div>
                                                            <div>Members online</div>
                                                        </div>
                                                        <div className="btn-group">
                                                            <button className="btn btn-transparent dropdown-toggle p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <svg className="c-icon">
                                                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-settings"></use>
                                                                </svg>
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right"><a className="dropdown-item" href="#">Action</a><a className="dropdown-item" href="#">Another action</a><a className="dropdown-item" href="#">Something else here</a></div>
                                                        </div>
                                                    </div>
                                                    <div className="c-chart-wrapper mt-3 mx-3" style={{ height: "70px" }}>
                                                        <canvas className="chart" id="card-chart1" height="70"></canvas>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-3">
                                                <div className="card text-white bg-info">
                                                    <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                                                        <div>
                                                            <div className="text-value-lg">9.823</div>
                                                            <div>Members online</div>
                                                        </div>
                                                        <div className="btn-group">
                                                            <button className="btn btn-transparent dropdown-toggle p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <svg className="c-icon">
                                                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-settings"></use>
                                                                </svg>
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right"><a className="dropdown-item" href="#">Action</a><a className="dropdown-item" href="#">Another action</a><a className="dropdown-item" href="#">Something else here</a></div>
                                                        </div>
                                                    </div>
                                                    <div className="c-chart-wrapper mt-3 mx-3" style={{ height: "70px" }}>
                                                        <canvas className="chart" id="card-chart2" height="70"></canvas>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-3">
                                                <div className="card text-white bg-warning">
                                                    <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                                                        <div>
                                                            <div className="text-value-lg">9.823</div>
                                                            <div>Members online</div>
                                                        </div>
                                                        <div className="btn-group">
                                                            <button className="btn btn-transparent dropdown-toggle p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <svg className="c-icon">
                                                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-settings"></use>
                                                                </svg>
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right"><a className="dropdown-item" href="#">Action</a><a className="dropdown-item" href="#">Another action</a><a className="dropdown-item" href="#">Something else here</a></div>
                                                        </div>
                                                    </div>
                                                    <div className="c-chart-wrapper mt-3" style={{ height: "70px" }}>
                                                        <canvas className="chart" id="card-chart3" height="70"></canvas>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-3">
                                                <div className="card text-white bg-danger">
                                                    <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                                                        <div>
                                                            <div className="text-value-lg">9.823</div>
                                                            <div>Members online</div>
                                                        </div>
                                                        <div className="btn-group">
                                                            <button className="btn btn-transparent dropdown-toggle p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <svg className="c-icon">
                                                                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-settings"></use>
                                                                </svg>
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right"><a className="dropdown-item" href="#">Action</a><a className="dropdown-item" href="#">Another action</a><a className="dropdown-item" href="#">Something else here</a></div>
                                                        </div>
                                                    </div>
                                                    <div className="c-chart-wrapper mt-3 mx-3" style={{ height: "70px" }}>
                                                        <canvas className="chart" id="card-chart4" height="70"></canvas>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <h4 className="card-title mb-0">Traffic</h4>
                                                        <div className="small text-muted">September 2019</div>
                                                    </div>
                                                    <div className="btn-toolbar d-none d-md-block" role="toolbar" aria-label="Toolbar with buttons">
                                                        <div className="btn-group btn-group-toggle mx-3" data-toggle="buttons">
                                                            <label className="btn btn-outline-secondary">
                                                                <input id="option1" type="radio" name="options" autocomplete="off" /> Day
</label>
                                                            <label className="btn btn-outline-secondary active">
                                                                <input id="option2" type="radio" name="options" autocomplete="off" checked="" /> Month
</label>
                                                            <label className="btn btn-outline-secondary">
                                                                <input id="option3" type="radio" name="options" autocomplete="off" /> Year
</label>
                                                        </div>
                                                        <button className="btn btn-primary" type="button">
                                                            <svg className="c-icon">
                                                                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-cloud-download"></use>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="c-chart-wrapper" style={{ height: "300px", marginTop: "40px" }}>
                                                    <canvas className="chart" id="main-chart" height="300"></canvas>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <div className="row text-center">
                                                    <div className="col-sm-12 col-md mb-sm-2 mb-0">
                                                        <div className="text-muted">Visits</div><strong>29.703 Users (40%)</strong>
                                                        <div className="progress progress-xs mt-2">
                                                            <div className="progress-bar bg-success" role="progressbar" style={{ width: "40%", ariaValuenow: "40", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md mb-sm-2 mb-0">
                                                        <div className="text-muted">Unique</div><strong>24.093 Users (20%)</strong>
                                                        <div className="progress progress-xs mt-2">
                                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: "20%", ariaValuenow: "20", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md mb-sm-2 mb-0">
                                                        <div className="text-muted">Pageviews</div><strong>78.706 Views (60%)</strong>
                                                        <div className="progress progress-xs mt-2">
                                                            <div className="progress-bar bg-warning" role="progressbar" style={{ width: "60%", ariaValuenow: "60", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md mb-sm-2 mb-0">
                                                        <div className="text-muted">New Users</div><strong>22.123 Users (80%)</strong>
                                                        <div className="progress progress-xs mt-2">
                                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: "80%", ariaValuenow: "80", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md mb-sm-2 mb-0">
                                                        <div className="text-muted">Bounce Rate</div><strong>40.15%</strong>
                                                        <div className="progress progress-xs mt-2">
                                                            <div className="progress-bar" role="progressbar" style={{ width: "40%", ariaValuenow: "40", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-6 col-lg-4">
                                                <div className="card">
                                                    <div className="card-header bg-facebook content-center">
                                                        <svg className="c-icon c-icon-3xl text-white my-4">
                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-facebook-f"></use>
                                                        </svg>
                                                    </div>
                                                    <div className="card-body row text-center">
                                                        <div className="col">
                                                            <div className="text-value-xl">89k</div>
                                                            <div className="text-uppercase text-muted small">friends</div>
                                                        </div>
                                                        <div className="c-vr"></div>
                                                        <div className="col">
                                                            <div className="text-value-xl">459</div>
                                                            <div className="text-uppercase text-muted small">feeds</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="card">
                                                    <div className="card-header bg-twitter content-center">
                                                        <svg className="c-icon c-icon-3xl text-white my-4">
                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-twitter"></use>
                                                        </svg>
                                                    </div>
                                                    <div className="card-body row text-center">
                                                        <div className="col">
                                                            <div className="text-value-xl">973k</div>
                                                            <div className="text-uppercase text-muted small">followers</div>
                                                        </div>
                                                        <div className="c-vr"></div>
                                                        <div className="col">
                                                            <div className="text-value-xl">1.792</div>
                                                            <div className="text-uppercase text-muted small">tweets</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <div className="card">
                                                    <div className="card-header bg-linkedin content-center">
                                                        <svg className="c-icon c-icon-3xl text-white my-4">
                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-linkedin"></use>
                                                        </svg>
                                                    </div>
                                                    <div className="card-body row text-center">
                                                        <div className="col">
                                                            <div className="text-value-xl">500+</div>
                                                            <div className="text-uppercase text-muted small">contacts</div>
                                                        </div>
                                                        <div className="c-vr"></div>
                                                        <div className="col">
                                                            <div className="text-value-xl">292</div>
                                                            <div className="text-uppercase text-muted small">feeds</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-header">Traffic &amp; Sales</div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="c-callout c-callout-info"><small className="text-muted">New Clients</small>
                                                                            <div className="text-value-lg">9,123</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-6">
                                                                        <div className="c-callout c-callout-danger"><small className="text-muted">Recuring Clients</small>
                                                                            <div className="text-value-lg">22,643</div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <hr className="mt-0" />
                                                                <div className="progress-group mb-4">
                                                                    <div className="progress-group-prepend"><span className="progress-group-text">Monday</span></div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: "34%", ariaValuenow: "34", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: "78%", ariaValuenow: "78", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-group mb-4">
                                                                    <div className="progress-group-prepend"><span className="progress-group-text">Tuesday</span></div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: "56%", ariaValuenow: "56", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: "94%", ariaValuenow: "94", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-group mb-4">
                                                                    <div className="progress-group-prepend"><span className="progress-group-text">Wednesday</span></div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: "12%", ariaValuenow: "12", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: "67%", ariaValuenow: "67", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-group mb-4">
                                                                    <div className="progress-group-prepend"><span className="progress-group-text">Thursday</span></div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: "43%", ariaValuenow: "43", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: "91%", ariaValuenow: "91", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-group mb-4">
                                                                    <div className="progress-group-prepend"><span className="progress-group-text">Friday</span></div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: "22%", ariaValuenow: "22", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: "73%", ariaValuenow: "73", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-group mb-4">
                                                                    <div className="progress-group-prepend"><span className="progress-group-text">Saturday</span></div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: "53%", ariaValuenow: "53", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: "82%", ariaValuenow: "82", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-group mb-4">
                                                                    <div className="progress-group-prepend"><span className="progress-group-text">Sunday</span></div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: "9%", ariaValuenow: "9", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: "69%", ariaValuenow: "69", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="c-callout c-callout-warning"><small className="text-muted">Pageviews</small>
                                                                            <div className="text-value-lg">78,623</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-6">
                                                                        <div className="c-callout c-callout-success"><small className="text-muted">Organic</small>
                                                                            <div className="text-value-lg">49,123</div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <hr className="mt-0" />
                                                                <div className="progress-group">
                                                                    <div className="progress-group-header">
                                                                        <svg className="c-icon progress-group-icon">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-user"></use>
                                                                        </svg>
                                                                        <div>Male</div>
                                                                        <div className="mfs-auto font-weight-bold">43%</div>
                                                                    </div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-warning" role="progressbar" style={{ width: "43%", ariaValuenow: "43", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-group mb-5">
                                                                    <div className="progress-group-header">
                                                                        <svg className="c-icon progress-group-icon">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-user-female"></use>
                                                                        </svg>
                                                                        <div>Female</div>
                                                                        <div className="mfs-auto font-weight-bold">37%</div>
                                                                    </div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-warning" role="progressbar" style={{ width: "43%", ariaValuenow: "43", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-group">
                                                                    <div className="progress-group-header align-items-end">
                                                                        <svg className="c-icon progress-group-icon">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-google"></use>
                                                                        </svg>
                                                                        <div>Organic Search</div>
                                                                        <div className="mfs-auto font-weight-bold mfe-2">191.235</div>
                                                                        <div className="text-muted small">(56%)</div>
                                                                    </div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-success" role="progressbar" style={{ width: "56%", ariaValuenow: "56", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-group">
                                                                    <div className="progress-group-header align-items-end">
                                                                        <svg className="c-icon progress-group-icon">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-facebook-f"></use>
                                                                        </svg>
                                                                        <div>Facebook</div>
                                                                        <div className="mfs-auto font-weight-bold mfe-2">51.223</div>
                                                                        <div className="text-muted small">(15%)</div>
                                                                    </div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-success" role="progressbar" style={{ width: "15%", ariaValuenow: "15", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-group">
                                                                    <div className="progress-group-header align-items-end">
                                                                        <svg className="c-icon progress-group-icon">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-twitter"></use>
                                                                        </svg>
                                                                        <div>Twitter</div>
                                                                        <div className="mfs-auto font-weight-bold mfe-2">37.564</div>
                                                                        <div className="text-muted small">(11%)</div>
                                                                    </div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-success" role="progressbar" style={{ width: "11%", ariaValuenow: "11", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-group">
                                                                    <div className="progress-group-header align-items-end">
                                                                        <svg className="c-icon progress-group-icon">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-linkedin"></use>
                                                                        </svg>
                                                                        <div>LinkedIn</div>
                                                                        <div className="mfs-auto font-weight-bold mfe-2">27.319</div>
                                                                        <div className="text-muted small">(8%)</div>
                                                                    </div>
                                                                    <div className="progress-group-bars">
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-success" role="progressbar" style={{ width: "8%", ariaValuenow: "8", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <br />
                                                        <table className="table table-responsive-sm table-hover table-outline mb-0">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th className="text-center">
                                                                        <svg className="c-icon">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-people"></use>
                                                                        </svg>
                                                                    </th>
                                                                    <th>User</th>
                                                                    <th className="text-center">Country</th>
                                                                    <th>Usage</th>
                                                                    <th className="text-center">Payment Method</th>
                                                                    <th>Activity</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-center">
                                                                        <div className="c-avatar"><img className="c-avatar-img" src="assets/img/avatars/1.jpg" alt="user@email.com" /><span className="c-avatar-status bg-success"></span></div>
                                                                    </td>
                                                                    <td>
                                                                        <div>Yiorgos Avraamu</div>
                                                                        <div className="small text-muted"><span>New</span> | Registered: Jan 1, 2015</div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <svg className="c-icon c-icon-xl">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/flag.svg#cif-us"></use>
                                                                        </svg>
                                                                    </td>
                                                                    <td>
                                                                        <div className="clearfix">
                                                                            <div className="float-left"><strong>50%</strong></div>
                                                                            <div className="float-right"><small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-success" role="progressbar" style={{ width: "50%", ariaValuenow: "50", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <svg className="c-icon c-icon-xl">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-cc-mastercard"></use>
                                                                        </svg>
                                                                    </td>
                                                                    <td>
                                                                        <div className="small text-muted">Last login</div><strong>10 sec ago</strong>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center">
                                                                        <div className="c-avatar"><img className="c-avatar-img" src="assets/img/avatars/2.jpg" alt="user@email.com" /><span className="c-avatar-status bg-danger"></span></div>
                                                                    </td>
                                                                    <td>
                                                                        <div>Avram Tarasios</div>
                                                                        <div className="small text-muted"><span>Recurring</span> | Registered: Jan 1, 2015</div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <svg className="c-icon c-icon-xl">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/flag.svg#cif-br"></use>
                                                                        </svg>
                                                                    </td>
                                                                    <td>
                                                                        <div className="clearfix">
                                                                            <div className="float-left"><strong>10%</strong></div>
                                                                            <div className="float-right"><small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: "10%", ariaValuenow: "10", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <svg className="c-icon c-icon-xl">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-cc-visa"></use>
                                                                        </svg>
                                                                    </td>
                                                                    <td>
                                                                        <div className="small text-muted">Last login</div><strong>5 minutes ago</strong>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center">
                                                                        <div className="c-avatar"><img className="c-avatar-img" src="assets/img/avatars/3.jpg" alt="user@email.com" /><span className="c-avatar-status bg-warning"></span></div>
                                                                    </td>
                                                                    <td>
                                                                        <div>Quintin Ed</div>
                                                                        <div className="small text-muted"><span>New</span> | Registered: Jan 1, 2015</div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <svg className="c-icon c-icon-xl">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/flag.svg#cif-in"></use>
                                                                        </svg>
                                                                    </td>
                                                                    <td>
                                                                        <div className="clearfix">
                                                                            <div className="float-left"><strong>74%</strong></div>
                                                                            <div className="float-right"><small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-warning" role="progressbar" style={{ width: "74%", ariaValuenow: "74", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <svg className="c-icon c-icon-xl">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-cc-stripe"></use>
                                                                        </svg>
                                                                    </td>
                                                                    <td>
                                                                        <div className="small text-muted">Last login</div><strong>1 hour ago</strong>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center">
                                                                        <div className="c-avatar"><img className="c-avatar-img" src="assets/img/avatars/4.jpg" alt="user@email.com" /><span className="c-avatar-status bg-secondary"></span></div>
                                                                    </td>
                                                                    <td>
                                                                        <div>Enéas Kwadwo</div>
                                                                        <div className="small text-muted"><span>New</span> | Registered: Jan 1, 2015</div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <svg className="c-icon c-icon-xl">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/flag.svg#cif-fr"></use>
                                                                        </svg>
                                                                    </td>
                                                                    <td>
                                                                        <div className="clearfix">
                                                                            <div className="float-left"><strong>98%</strong></div>
                                                                            <div className="float-right"><small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: "98%", ariaValuenow: "98", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <svg className="c-icon c-icon-xl">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-cc-paypal"></use>
                                                                        </svg>
                                                                    </td>
                                                                    <td>
                                                                        <div className="small text-muted">Last login</div><strong>Last month</strong>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center">
                                                                        <div className="c-avatar"><img className="c-avatar-img" src="assets/img/avatars/5.jpg" alt="user@email.com" /><span className="c-avatar-status bg-success"></span></div>
                                                                    </td>
                                                                    <td>
                                                                        <div>Agapetus Tadeáš</div>
                                                                        <div className="small text-muted"><span>New</span> | Registered: Jan 1, 2015</div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <svg className="c-icon c-icon-xl">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/flag.svg#cif-es"></use>
                                                                        </svg>
                                                                    </td>
                                                                    <td>
                                                                        <div className="clearfix">
                                                                            <div className="float-left"><strong>22%</strong></div>
                                                                            <div className="float-right"><small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: "22%", ariaValuenow: "22", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <svg className="c-icon c-icon-xl">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-cc-apple-pay"></use>
                                                                        </svg>
                                                                    </td>
                                                                    <td>
                                                                        <div className="small text-muted">Last login</div><strong>Last week</strong>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center">
                                                                        <div className="c-avatar"><img className="c-avatar-img" src="assets/img/avatars/6.jpg" alt="user@email.com" /><span className="c-avatar-status bg-danger"></span></div>
                                                                    </td>
                                                                    <td>
                                                                        <div>Friderik Dávid</div>
                                                                        <div className="small text-muted"><span>New</span> | Registered: Jan 1, 2015</div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <svg className="c-icon c-icon-xl">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/flag.svg#cif-pl"></use>
                                                                        </svg>
                                                                    </td>
                                                                    <td>
                                                                        <div className="clearfix">
                                                                            <div className="float-left"><strong>43%</strong></div>
                                                                            <div className="float-right"><small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-success" role="progressbar" style={{ width: "43%", ariaValuenow: "43", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <svg className="c-icon c-icon-xl">
                                                                            <use xlinkHref="vendors/@coreui/icons/svg/brand.svg#cib-cc-amex"></use>
                                                                        </svg>
                                                                    </td>
                                                                    <td>
                                                                        <div className="small text-muted">Last login</div><strong>Yesterday</strong>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </main> */}
                            <footer className="c-footer">
                                <div><a href="https://coreui.io">CoreUI</a> © 2020 creativeLabs.</div>
                                <div className="ml-auto">Powered by&nbsp;<a href="https://coreui.io/">CoreUI</a></div>
                            </footer>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Header;